
<template>

  <div>
    <!-- loading state -->
    <template v-if="isLoading">
      <div class="text-center">
        <b-spinner variant="primary" label="Loading" />
      </div>
    </template>


    <!-- content section  -->
    <template v-else>

        <!--{{ fetchTaskData }}-->

        <b-card no-body>
          <!-- card header -->
        <b-card-header class="align-items-baseline pb-1">
          <div>

            <b-card-title class="mb-25">
              <!-- back option -->
              <span @click="handleCloseForm">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="20"
                  class="align-bottom  cursor-pointer"
                />
              </span>
              {{  fetchTaskData.ftyCodigo }} <span class="text-muted">#</span>{{ fetchTaskData.frmSecuencial }}
            </b-card-title>


          </div>

          <div>

            <!-- latest status -->
              <b-badge
                pill
                :variant="getLightFormStatusVariant()"
              >
                <span class="text-uppercase">
                  {{ getTaskStatusName() }}
                </span>
              </b-badge>

          </div>



        </b-card-header>

        <b-row class="px-2">
          <b-col class="text-right">
            Fecha de vencimiento: <span class="text-capitalize"><strong>{{ getFormatDateTime(fetchTaskData.frmFechaLimite) }}</strong></span>

          </b-col>
        </b-row>

        <!-- tabs info -->

        <b-tabs class="p-2" pills lazy>

          <!-- info colaborador-->
          <b-tab active>
            <template #title>
              <feather-icon
                icon="UserIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Colaborador</span>
            </template>

            <!-- header personal info -->
            <div class="d-flex">
              <feather-icon
                icon="UserIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Personal
              </h4>
            </div>

            <b-row>
              <b-col md="12">
                <b-card no-body>
                  <b-card-body class="pb-0">
                    <dl class="row mb-0">
                      <dt class="col-sm-3">
                        Nombres
                      </dt>
                      <dd class="col-sm-9">
                        {{ employeeData.empNombres }}
                      </dd>
                    </dl>
                    <dl class="row mb-0">
                      <dt class="col-sm-3">
                        Apellidos
                      </dt>
                      <dd class="col-sm-9">
                        {{ employeeData.empApellidos }}
                      </dd>
                    </dl>
                    <dl class="row mb-0">
                      <dt class="col-sm-3">
                        Correo electrónico
                      </dt>
                      <dd class="col-sm-9">
                        {{ employeeData.empEmail }}
                      </dd>
                    </dl>
                    <dl class="row mb-0">
                      <dt class="col-sm-3 text-truncate">
                        Identificación
                      </dt>
                      <dd class="col-sm-9">
                        {{ employeeData.empIdentificacion }}
                      </dd>
                    </dl>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>



            <!-- Header: Ubicacion -->
            <div class="d-flex">
              <feather-icon
                icon="MapPinIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Ubicación
              </h4>
            </div>


            <b-row>
              <b-col md="12">
                <b-card no-body>
                  <b-card-body class="pb-0">
                    <dl class="row mb-0">
                      <dt class="col-sm-3">
                        Lugar
                      </dt>
                      <dd class="col-sm-9">
                        {{ employeeData.plaNombre }}
                      </dd>
                    </dl>
                    <dl class="row mb-0">
                      <dt class="col-sm-3">
                        Oficina
                      </dt>
                      <dd class="col-sm-9">
                        {{ employeeData.offNombre }}
                      </dd>
                    </dl>
                    <dl class="row mb-0">
                      <dt class="col-sm-3">
                        Departamento
                      </dt>
                      <dd class="col-sm-9">
                        {{ employeeData.secNombre }}
                      </dd>
                    </dl>
                    <dl class="row mb-0">
                      <dt class="col-sm-3 text-truncate">
                        Cargo
                      </dt>
                      <dd class="col-sm-9">
                        {{ employeeData.posNombre }}
                      </dd>
                    </dl>

                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>

          <!-- documentos -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="PaperclipIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Documentos</span>
            </template>

            <b-list-group class="mb-2">
              <b-list-group-item
                class="d-flex justify-content-between"
                v-for="document in documentsData" :key="document.docId"
              >
                <span>{{ document.docName }}</span>

                <b-button
                  variant="link"
                  class="p-0 text-primary"
                  :disabled="isBusy"
                  @click="handleShowFile(document)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="20"
                  />
                </b-button>
              </b-list-group-item>
            </b-list-group>

          </b-tab>

          <!-- tareas -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="CheckSquareIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Tareas</span>
            </template>

            <tarea-to-do-list  :frm-id="fetchTaskData.frmId" :fty-id="fetchTaskData.ftyId" :tasks-list="systemsData"/>



          </b-tab>


          <!-- log actividad -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="MessageSquareIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Actividad</span>
            </template>

            <tarea-activity :frm-id="fetchTaskData.frmId"/>

          </b-tab>


        </b-tabs>


      </b-card>



    </template>

  </div>

</template>

<script>

import {
  BSpinner,
  BCard,
  BCardTitle,
  BCardHeader,
  BDropdownItem,
  BBadge,
  BDropdown,
  BCardText,
  BTabs,
  BTab, BCol, BRow, BCardBody, BListGroup, BListGroupItem, BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import { resolveLightFormStatusVariant, resolveTaskStatusName, resolveFormatoFechaHora } from '@/modules/common/utils'

export default {

  name: 'TareaOpen',
  components: {
    BButton,
    BListGroupItem,
    BListGroup,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    BDropdown,
    BBadge,
    BDropdownItem,
    BCardHeader,
    BCardTitle,
    BSpinner,
    BCard,
    BTabs,
    BTab,

    TareaToDoList: () => import('@/modules/ejecutante/views/tareas/TareaToDoList.vue'),
    TareaActivity: () => import('@/modules/ejecutante/views/tareas/TareaActivity.vue')
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      errors: [],
      isLoading: false,
      isBusy: false,
     // taskData: []
    }
  },

  computed: {

    ...mapGetters('tasks-module', ['fetchTaskData']),

    queryKey(){
      const { key } = this.$route.query
      return key ?? null
    },

    employeeData(){
      return this.fetchTaskData.employee
    },

    documentsData(){
      return this.fetchTaskData.documents
    },

    systemsData(){
      return this.fetchTaskData.sistemas
    },




  },

  methods: {

    ...mapActions('tasks-module', ['loadTaskForm']),
    ...mapActions('file-module', ['showDocument']),

    // cerrar formulario
    handleCloseForm(){
      this.$router.push({name: 'tareas'})
    },

    // estilo para el estado
    getLightFormStatusVariant(){

      const [ latestStatus ] = this.fetchTaskData.statuses

      const status = {
        status: this.fetchTaskData.frmEstado,
        latestStatus: latestStatus.fstStatus
      }

      return resolveLightFormStatusVariant(status)
    },


    // nombre de la tarea
    getTaskStatusName( ){
      const [ latestStatus ] = this.fetchTaskData.statuses
      const status = {
        status: this.fetchTaskData.frmEstado,
        latestStatus: latestStatus.fstStatus
      }
      return resolveTaskStatusName( status )
    },

    // formato para datetime
    getFormatDateTime( date ){
      return resolveFormatoFechaHora(date)
    },

    //
    async handleShowFile( file ){

      try {

        const response = await this.showDocument( file.docUuid )

        // Crear una URL del archivo PDF recibido
        const pdfUrl = URL.createObjectURL(response);
        // Abrir el archivo PDF en una nueva pestaña del navegador
        window.open(pdfUrl, '_blank');

      } catch (error) {

        console.log("UNEXPECTED", error)

      } finally {

      }

    },

    // cargar data
    async loadData(){

      try {
        this.isLoading = true
        await this.loadTaskForm( { uuid: this.queryKey } )
      } catch (error) {

        console.log("UNEXPECTED", error)

      } finally {
        this.isLoading = false
      }

    }
  },

  async created() {

    await this.loadData()

  }

}

</script>


<style scoped lang="scss">

</style>
